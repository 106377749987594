//experience
const experience = [
  {
    duration: "Dec 2017 - Present",
    cname: "Yatnam Technologies",
    place: "Ernakulam, kerala, India",
    color: "pink",
  },
  {
    duration: "Jul 2015 - Oct 2017",
    cname: "Bharathi Information Technologies",
    place: "Ernakulam, kerala, India",
    color: "light",
  },
];
//education
const education = [
  {
    duration: "2010",
    school: "Higher Secondary - GVHSS Pullanur",
    place: "Malappuram, kerala, India",
    color: "light",
  },
  {
    duration: "2008",
    school: "High School - VHMHSS Morayur",
    place: "Malappuram, kerala, India",
    color: "pink",
  },
];

//skills
const skills = [
  { way: `HTML`, progress: 95 },
  { way: `CSS & CSS3`, progress: 95 },
  { way: `SASS`, progress: 95 },
  { way: `Bootstrap & Tailwind CSS`, progress: 95 },
  { way: `JavaScript, jQuery & React`, progress: 75 },
  { way: `Wordpress`, progress: 55 },
  { way: `Adobe Creative Suite`, progress: 85 },
];
//knowledge
const knowledge = [
  `HTML`,
  `HTML5`,
  `CSS`,
  `CSS3`,
  `SASS`,
  `Bootstrap`,
  `Tailwind CSS`,
  `JavaScript`,
  `jQuery`,
  `Wordpress`,
  `React JS`,
  `Node Js`,
  `Rest API`,
  `Git`,
  `Photoshop`,
  `Illustrator`,
  `Adobe XD`,
  `Figma`,
  `Email Templates`,
  `Mailchimp`,
];

function Resume() {
  return (
    <div className="relative">
      <h1 className="xl:text-5xl text-3xl font-bold text-prime xl:mb-10 mb-6">
        Resume
      </h1>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          <h5 className="text-2xl font-medium text-prime mb-5">Experience</h5>
          {experience.map((exp) => (
            <div key={exp.cname} className="w-full mb-8">
              <div className={`bg-${exp.color} rounded-xl md:p-6 p-4`}>
                <span className="block md:text-sm text-xs text-prime mb-2">
                  {exp.duration}
                </span>
                <p className="lg:text-lg text-base text-slate-500 leading-relaxed">
                  {exp.cname}
                </p>
                <span className="block md:text-sm text-xs text-black mt-2">
                  {exp.place}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full md:w-1/2 px-4">
          <h5 className="text-2xl font-medium text-prime mb-5">Education</h5>
          {education.map((study) => (
            <div key={study.school} className="w-full mb-8">
              <div className={`bg-${study.color} rounded-xl md:p-6 p-4`}>
                <span className="block md:text-sm text-xs text-prime mb-2">
                  {study.duration}
                </span>
                <p className="lg:text-lg text-base text-slate-500 leading-relaxed">
                  {study.school}
                </p>
                <span className="block md:text-sm text-xs text-black mt-2">
                  {study.place}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="lg:-mx-10 lg:p-10 -mx-5 py-6 px-5 bg-greysm">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <h5 className="text-2xl font-medium text-prime mb-5">
              Working Skills
            </h5>
            <ul>
              {skills.map((skill) => (
                <li key={skill.way} className="mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm text-prime">{skill.way}</span>
                    <span className="text-sm text-prime">
                      {skill.progress}%
                    </span>
                  </div>
                  <div
                    className={`bg-slate-400 h-2 overflow-hidden rounded-xl`}
                  >
                    <div
                      style={{
                        width: `${skill.progress}%`,
                      }}
                      className={`h-full bg-smsub`}
                    ></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full md:w-1/2 px-4">
            <h5 className="text-2xl font-medium text-prime mb-5">Knowledges</h5>
            <ul className="flex flex-wrap">
              {knowledge.map((knowledge, index) => (
                <li
                  className="bg-[#edf2f2] px-4 py-2  rounded-md mb-3 mr-3 text-xs md:text-sm"
                  key={index}
                >
                  {knowledge}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
