import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faReact, faWordpress } from "@fortawesome/free-brands-svg-icons";

const services = [
  {
    icon: faPalette,
    color: "light",
    title: "UI/UX Design",
    description:
      "Creating intuitive, user-friendly interfaces that make technology accessible to everyone. It's about understanding user needs and behaviors, and designing solutions that are not only visually appealing but also functional and easy to use.",
  },
  {
    icon: faLaptopCode,
    color: "pink",
    title: "HTML Development",
    description:
      "Create clean, efficient, and responsive web pages. I have a strong background in HTML5, CSS3, and JavaScript, and I am committed to delivering high-quality code that meets the needs of my clients.",
  },
  {
    icon: faWordpress,
    color: "pink",
    title: "CMS Development",
    description:
      "Specialize in creating dynamic and user-friendly content management systems that make it easy for clients to update, publish, and manage their content. like wordpress, strapi",
  },
  {
    icon: faReact,
    color: "light",
    title: "React Development",
    description:
      "Building dynamic and interactive web applications using React, Redux, and other modern web technologies. I specialize in creating high-quality, performant, and user-friendly applications that meet the unique needs of my clients.",
  },
];

function Home() {
  const [years, setYears] = useState(null);

  useEffect(() => {
    const july2015 = new Date("2015-07-01");
    const currentDate = new Date();

    let yearsDifference = currentDate.getFullYear() - july2015.getFullYear();

    if (
      currentDate.getMonth() < july2015.getMonth() ||
      (currentDate.getMonth() === july2015.getMonth() &&
        currentDate.getDate() < july2015.getDate())
    ) {
      yearsDifference--;
    }

    setYears(yearsDifference);
  }, []);

  return (
    <div className="relative">
      <h1 className="xl:text-5xl text-3xl font-bold text-prime xl:mb-10 mb-6">
        About
      </h1>
      <p className="lg:text-base md:text-sm text-[13px] max-md:leading-normal text-dark mb-4">
        Innovative and results-oriented professional with over {years}+ years of
        experience in web design and development across diverse industries.
        Proven track record of successfully identifying and addressing the
        technological needs of companies through creative innovation. Proficient
        in database development, user interface design, code writing and
        testing, and adept at troubleshooting simple and complex issues. Known
        for implementing new features based on user feedback to enhance overall
        user experience.
      </p>
      <p className="lg:text-base md:text-sm text-[13px] max-md:leading-normal text-dark">
        Over the years, I have honed my skills in a variety of web technologies,
        from HTML, CSS, WordPress and JavaScript to modern frameworks like
        React.js. My extensive experience allows me to navigate complex
        technical challenges with ease, while my creative mindset enables me to
        envision innovative solutions that push the boundaries of possibility.
        Beyond my technical skills, I pride myself on my ability to communicate
        effectively, collaborate seamlessly with cross-functional teams, and
        adapt quickly to new challenges and environments.
      </p>
      <div className="bg-pink bg-light hidden"></div>
      <div className="mt-14">
        <h5 className="text-3xl font-medium text-prime mb-5">What I Do!</h5>
        <div className="flex flex-wrap -mx-4">
          {services.map((service) => (
            <div key={service.title} className="px-4 w-full md:w-1/2 mb-8">
              <div
                className={`flex max-md:flex-wrap items-top bg-${service.color} rounded-xl p-6`}
              >
                <div className="flex-none w-14 text-sub md:pt-1 max-md:mb-3">
                  <FontAwesomeIcon icon={service.icon} size={"xl"} />
                </div>
                <div className="flex flex-col">
                  <h5 className="text-xl font-medium text-prime mb-2">
                    {service.title}
                  </h5>
                  <p className="text-xs text-slate-600 leading-relaxed">
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
