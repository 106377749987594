import { useState } from "react";
import worklistData from "./workcontent.json";
import { Link } from "react-router-dom";

function Image({ src, alt, className }) {
  return <img className={className} src={src} alt={alt} />;
}

function Works() {
  const [works, setWorks] = useState(worklistData.projects);
  const [activeBtn, setActive] = useState("All");

  const filterItem = (cateItem) => {
    if (cateItem === "All") {
      setWorks(worklistData.projects);
    } else {
      const updatedItems = worklistData.projects.filter((curElem) => {
        if (Array.isArray(curElem.category)) {
          return curElem.category.some((cat) => cat === cateItem);
        } else {
          return curElem.category === cateItem;
        }
      });

      setWorks(updatedItems);
    }

    setActive(cateItem);
  };

  const categories = ["All", "bootstrap", "tailwindcss", "wordpress", "react"];

  return (
    <div className="relative">
      <h1 className="xl:text-5xl text-3xl font-bold text-prime xl:mb-10 mb-6">
        My Projects
      </h1>
      <div className="filter flex max-md:flex-wrap items-center justify-center mb-8">
        {categories.map((category) => (
          <button
            key={category}
            type="button"
            className={`text-smsub max-md:mb-2 md:text-sm text-xs relative uppercase transition-all font-medium px-2 py-1 bg-transparent ${
              activeBtn === category ? "active" : ""
            }`}
            onClick={(e) => filterItem(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <ul className="-mx-4 flex flex-wrap">
        {works.map((work, index) => (
          <li className="px-4 w-full md:w-1/2 md:mb-8 mb-4" key={index}>
            <div className="flex flex-col md:p-4 p-3 rounded-xl bg-slate-200 shadow-xl">
              <Link to={work.url} target="_blank" className="group">
                <div className="overflow-hidden rounded-xl lg:h-48 h-36">
                  <Image
                    className={`w-full h-full object-cover transition-all group-hover:scale-125 group-hover:rotate-6 duration-700`}
                    src={require(`../img/${work.image}`)}
                    alt={work.title}
                  />
                </div>
                <div className="pt-4 text-left text-prime font-bold md:text-lg text-sm">
                  {work.title}
                </div>
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Works;
