import React from "react";

function NotFound() {
  return (
    <div className="lg:text-3xl text-lg text-prime h-96 flex items-center justify-center text-center font-bold">
      Page Not Found
    </div>
  );
}

export default NotFound; 
