import axios from "axios";
import { useState } from "react";

function ContactForm() {
  const initialFormData = {
    name: "",
    email: "",
    message: "",
    success: "",
    error: "",
  };

  const [formData, setForm] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
      error: "",
    }));
  };

  const clearForm = () => {
    setForm(initialFormData);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const serviceId = "service_2kx1dk3";
    const templateId = "template_z34irrc";
    const publicKey = "EGpoqfuwV7Ww1_Kv5";

    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_name: formData.name,
        from_email: formData.email,
        to_name: "Shihabudheen",
        message: formData.message,
      },
    };

    try {
      await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
      setForm({
        ...formData,
        success: "Your Message has been sent successfully!",
      });
      setTimeout(() => {
        setForm((prevData) => ({
          ...prevData,
          success: "",
        }));
        clearForm();
      }, 3000);
    } catch (error) {
      setForm((prevData) => ({
        ...prevData,
        error: "Failed to send message. Please try again later.",
      }));
    }
  };

  return (
    <form onSubmit={sendEmail}>
      <div className="mb-8">
        <label htmlFor="name" className="text-sm text-smsub block">
          Name
        </label>
        <div className="control">
          <input
            className="py-3 text-sm text-black border-b border-solid border-smsub focus:outline-none w-full h-11 bg-transparent"
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-8">
        <label className="text-sm text-smsub block" htmlFor="email">
          Email
        </label>
        <div className="control">
          <input
            className="py-3 text-sm text-black border-b border-solid border-smsub focus:outline-none w-full h-11 bg-transparent"
            required
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-8">
        <label className="text-sm text-smsub block" htmlFor="message">
          Message
        </label>
        <div className="control">
          <textarea
            className="py-3 text-sm text-black border-b border-solid border-smsub focus:outline-none w-full h-32 resize-none bg-transparent"
            required
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>
      <div className="">
        <button
          type="submit"
          className="xl:w-44 w-36 xl:h-14 h-12 bg-prime text-white xl:text-base text-sm uppercase rounded-xl tracking-widest font-medium"
        >
          Submit
        </button>
      </div>
      {formData.success && (
        <div
          className="text-green-700 text-center lg:text-sm text-xs mt-5 transition-all duration-500"
          role="alert"
        >
          {formData.success}
        </div>
      )}
      {formData.error && (
        <div
          className="text-red-700 text-center lg:text-sm text-xs mt-5 transition-all duration-500"
          role="alert"
        >
          {formData.error}
        </div>
      )}
    </form>
  );
}

export default ContactForm;
