import React, { useEffect, useState } from "react";
import MenuLink from "./MenuLink";
import ScrollToTopOnNav from "./ScrollToTopOnNav";

function SideMenu() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <>
      <ScrollToTopOnNav />
      <div
        className={`px-4 xl:px-6 relative xl:w-2/12 w-full max-xl:order-1 max-xl:sticky ${
          scrolled ? "top-7" : ""
        }`}
      >
        <div
          className={`menu xl:sticky top-0 max-xl:flex max-xl:justify-center `}
        >
          <MenuLink />
        </div>
      </div>
    </>
  );
}

export default SideMenu;
