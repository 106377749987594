import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBriefcase,
  faFileLines,
  faAddressBook,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

const menuitems = [
  {
    url: "/",
    title: "Home",
    icon: faHome,
  },
  {
    url: "/about",
    title: "About",
    icon: faUser,
    class: "hidden max-lg:block",
  },
  {
    url: "/resume",
    title: "Resume",
    icon: faFileLines,
  },
  {
    url: "/projects",
    title: "Projects",
    icon: faBriefcase,
  },
  {
    url: "/contact",
    title: "Contact",
    icon: faAddressBook,
  },
];

function MenuLink() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <ul
      className={`bg-white lg:p-6 lg:rounded-2xl max-xl:inline-flex max-lg:transition-all max-lg:justify-center max-lg:rounded-xl max-lg:p-3 max-xl:mb-8 ${
        scrolled ? "max-xl:shadow-xl" : ""
      }`}
    >
      {menuitems.map((menu, index) => (
        <li
          key={index}
          className={`xl:mb-4 xl:mx-auto max-xl:mx-2 max-xl:w-24 max-lg:w-auto ${menu.class}`}
        >
          <NavLink
            to={menu.url}
            className="lg:px-4 lg:py-6 lg:bg-slate-100 lg:rounded-xl text-sm leading-none text-smsub flex items-center lg:justify-center lg:flex-col max-lg:p-2 max-lg:rounded-xl"
          >
            <span className="max-lg:size-8 max-sm:size-6 max-lg:flex max-lg:items-center max-lg:justify-center">
              <FontAwesomeIcon icon={menu.icon} size={"xl"} />
            </span>
            <span className="block lg:mt-2 mt-1 max-lg:ml-2 max-lg:hidden">
              {menu.title}
            </span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default MenuLink;
