import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <div className="relative">
      <h1 className="xl:text-5xl text-3xl font-bold text-prime xl:mb-10 mb-6">
        Contact
      </h1>
      <div className="bg-slate-100 rounded-xl lg:p-10 px-5 py-6">
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact; 
