import profilepic from "../img/profile.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {
  faMobileScreenButton,
  faEnvelopeOpenText,
  faLocationDot,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MyPDF from "../img/shihabudheen.pdf";

const about = [
  {
    title: "Phone",
    detail: "+91 9809 681 899",
    icon: faMobileScreenButton,
  },
  {
    title: "Email",
    detail: "shihabudheenmgm @gmail.com",
    icon: faEnvelopeOpenText,
  },
  {
    title: "Location",
    detail: "Kerala, India",
    icon: faLocationDot,
  },
  {
    title: "Birthday",
    detail: "January 01, 1993",
    icon: faCalendarDays,
  },
];

function Aside() {
  return (
    <>
      <aside className="profile xl:w-3/12 lg:px-4 max-xl:order-2 lg:w-2/6 w-full">
        <div className="sticky top-0">
          <div className="w-40 h-40 overflow-hidden rounded-2xl mx-auto -mb-16 border-2 border-solid border-white">
            <img src={profilepic} />
          </div>
          <div className="bg-white lg:p-6 !pt-24 rounded-2xl">
            <div className="text-center">
              <h1 className="text-2xl md:text-3xl 2xl:text-4xl font-bold text-prime mb-2">
                Shihabudheen
              </h1>
              <h5 className="text-sm md:text-base text-sub font-medium">
                Front-end Developer
              </h5>
              <ul className="mt-6 flex items-center justify-center">
                <li className="mx-1">
                  <Link
                    to={"https://www.linkedin.com/in/shihabudheen-p-a52a73153"}
                    target="_blank"
                    className="px-4 py-3 bg-slate-100 rounded-lg text-base leading-none text-sub flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} size={"lg"} />
                  </Link>
                </li>
              </ul>
              <div className="mt-8 bg-slate-100 rounded-xl p-4">
                {about.map((me, index) => (
                  <div
                    key={index}
                    className="flex items-center py-3 border-b border-solid border-grey-500"
                  >
                    <div className="p-3 w-11 flex-auto text-center bg-white rounded-lg text-base leading-none text-smsub flex items-center justify-center shadow-md">
                      <FontAwesomeIcon icon={me.icon} size={"sm"} />
                    </div>
                    <div className="w-full pl-4 flex flex-col text-left">
                      <span className="text-xs text-gray-500 block mb-1 leading-none">
                        {me.title}
                      </span>
                      <span className="2xl:text-base text-sm font-medium text-prime">
                        {me.detail}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-6">
                <Link
                  to={MyPDF}
                  download
                  target="_blank"
                  className="px-4 py-3 bg-prime rounded-lg text-white font-medium hover:bg-opacity-75 transition duration-200 ease-in-out flex items-center justify-center max-w-40 mx-auto"
                >
                  Download CV
                </Link>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Aside;
